import AppStatusModel from "../Models/AppStatusModel";

import { apiLoginRequest } from "../Config";
import { IPublicClientApplication } from "@azure/msal-browser";

export default class StatusService {
    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async getAppStatuses(): Promise<AppStatusModel[]> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const apps: AppStatusModel[] = [];
        const accessToken = await this.acquireAccessToken();

        await fetch(baseUrl + '/api/Status', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        }).then(async function (response) {
            await response.json().then(data => {
                if (data) {
                    data.forEach((responseObject: any) => {
                        const appStatus = {
                            name: responseObject.Name,
                            status: responseObject.Status,
                            lastModifiedDate: responseObject.LastModifiedDate,
                        };
                        apps.push(appStatus);
                    });
                }
            })
        }).catch((error: Error) => {
            console.log(error.message);
        });

        return apps;
    }

    acquireAccessToken = async () => {
        // const activeAccount = this.msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API        
        // const accounts = this.msalInstance.getAllAccounts();
        // if (!activeAccount && accounts.length === 0) {
        //     /*
        //     * User is not signed in. Throw error or wait for user to login.
        //     * Do not attempt to log a user in outside of the context of MsalProvider
        //     */
        // }    

        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }


        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}

import CompanyType from "../Models/CompanyType";
import DeliveryNote from "../Models/DeliveryNote";

const SessionStorageService = {
    getFilter(): any {
        const stored = sessionStorage.getItem("filter");
        if (!stored) {
            return null;
        }
        return JSON.parse(stored);
    },

    saveFilter(data: any) {
        const json = JSON.stringify(data);
        sessionStorage.setItem("filter", json);
    },

    getCompanyFilter(): CompanyType {
        const stored = sessionStorage.getItem("companyFilter");
        if (!stored) {
            return CompanyType.All;
        }
        return JSON.parse(stored);
    },

    saveCompanyFilter(data: CompanyType) {
        const json = JSON.stringify(data);
        sessionStorage.setItem("companyFilter", json);
    },

    getDate(): any {
        const stored = sessionStorage.getItem("date");
        if (!stored) {
            return null;
        }
        return new Date(JSON.parse(stored));
    },

    saveDate(data: Date): void {
        const json = JSON.stringify(data);
        sessionStorage.setItem("date", json);
    },

    getCheckedState(): any {
        const stored = sessionStorage.getItem("checkedState");
        if (!stored) {
            return null;
        }
        return JSON.parse(stored);
    },

    saveCheckedState(checkedState: boolean): void {
        const json = JSON.stringify(checkedState);
        sessionStorage.setItem("checkedState", json);
    },

    getOrderOfDeliveryNotes(): number[] {
        const stored = sessionStorage.getItem("orderDeliveryNotes");
        if (!stored) {
            return [];
        }
        const orderIds = JSON.parse(stored);
        return orderIds;
    },

    saveOrderOfDeliveryNotes(order: DeliveryNote[]): void {
        const orderIds: number[] = order.map(dn => dn.id);
        const json = JSON.stringify(orderIds);
        sessionStorage.setItem("orderDeliveryNotes", json);
    }
}

export default SessionStorageService;
import React from 'react';
import StatusService from '../Services/StatusesService';
import AppStatusModel from '../Models/AppStatusModel';
import { useMsal } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Redirect } from 'react-router-dom';

const StatusContent = () => {

    const { instance } = useMsal();
    const service = React.useMemo(() => new StatusService(instance), [instance]);

    const [appStatuses, setAppStatuses] = React.useState<AppStatusModel[] | null>(null);

    React.useEffect(() => {
        if (!appStatuses) {
            getAppStatuses();
        }
    }, []);

    const getAppStatuses = async () => {
        let data = await service.getAppStatuses();
        setAppStatuses(data);
    };

    if (appStatuses && appStatuses.length > 0) {
        return (
            <div className="container">
                {appStatuses.map((appStatus) => {
                    let lastModifiedDate = new Date(appStatus.lastModifiedDate);
                    let now = new Date();
                    let timeDiff = (now.getTime() - lastModifiedDate.getTime());
                    if (appStatus.status === "OK") {
                        if (timeDiff < 60 * 60 * 1000) {
                            return (
                                <div className="alert alert-success" role="alert">
                                    <strong>{appStatus.name}</strong> Laatste update: {lastModifiedDate.toLocaleString('nl-BE')}
                                </div>
                            );
                        } else {
                            return (
                                <div className="alert alert-warning" role="alert">
                                    <strong>{appStatus.name}</strong> Laatste update: {lastModifiedDate.toLocaleString('nl-BE')}
                                </div>
                            );
                        }
                    }
                    else {
                        return (
                            <div className="alert alert-danger" role="alert">
                                <strong>{appStatus.name}</strong> Laatste update: {lastModifiedDate.toLocaleString('nl-BE')}
                            </div>
                        );
                    }
                })
                }
            </div>
        );
    }

    return null;
}

export default function Status() {

    return (
        <div className="container">
            <AuthenticatedTemplate>
                <StatusContent />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Redirect to="/" />
            </UnauthenticatedTemplate>
        </div>

    );
}
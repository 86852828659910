import DeliveryNote from "../Models/DeliveryNote";
import SignedDeliveryNote from "../Models/SignedDeliveryNote";
import CompanyType from "../Models/CompanyType";
import { apiLoginRequest } from "../Config";
import { IPublicClientApplication } from "@azure/msal-browser";

export default class DeliveryNoteService {
    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async getAllDeliveryNotesByFilters(companyType: CompanyType, type: string, signed: boolean): Promise<DeliveryNote[]> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = `${baseUrl}/api/DeliveryNotesByFilters`;
        let deliveryNotes: DeliveryNote[] = [];
        const accessToken = await this.acquireAccessToken();
        await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            }),
            body: JSON.stringify({
                companyType: companyType,
                type: type,
                signed: signed
            })
        }).then(async function (response) {
            await response.json().then(data => {
                if (data) {
                    data.forEach((responseObject: any) => {
                        let deliveryNote: DeliveryNote = mapJsonObjectToDeliveryNote(responseObject)
                        deliveryNotes.push(deliveryNote);
                    });
                }
            })
        }).catch((error: Error) => {
            console.log(error.message)
        });

        return deliveryNotes;
    }


    public async getByteArrayFromPdf(pdfReference: string): Promise<ArrayBuffer> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = `${baseUrl}/api/GetByteArrayFromPdf`;
        const accessToken = await this.acquireAccessToken();
        let pdfBytes: ArrayBuffer = await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            }),
            body: JSON.stringify(pdfReference)
        }).then(async function (response) {
            return await response.json().then(data => {
                if (data) {
                    return data;
                }
            })
        }).catch((error: Error) => {
            console.log(error.message)
            return null;
        });

        return pdfBytes;
    }

    public async sendSignedDeliveryNoteWithEmail(signedDeliveryNote: SignedDeliveryNote): Promise<boolean> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = `${baseUrl}/api/SignedNoteWithEmail`;
        let updated = false;
        const accessToken = await this.acquireAccessToken();
        await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            }),
            body: JSON.stringify(signedDeliveryNote)
        }).then(async function (response) {
            await response.json().then(data => {
                if (data.StatusCode === 400) {
                    throw new Error();
                }
                updated = true;
            })
        }).catch(() => {
            return false;
        })

        return updated;
    }

    public async sendSignedDeliveryNote(signedDeliveryNote: SignedDeliveryNote): Promise<boolean> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = `${baseUrl}/api/SignedNote`;
        let updated = false;
        const accessToken = await this.acquireAccessToken();
        await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            }),
            body: JSON.stringify(signedDeliveryNote)
        }).then(async function (response) {
            await response.json().then(data => {
                if (data.StatusCode === 400) {
                    throw new Error();
                }
                updated = true;
            })
        }).catch(() => {
            return false;
        })

        return updated;
    }

    public async markSignedDeliveryNote(signedDeliveryNote: SignedDeliveryNote): Promise<boolean> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = `${baseUrl}/api/MarkSignedNote`;
        let updated = false;
        const accessToken = await this.acquireAccessToken();
        await fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            }),
            body: JSON.stringify(signedDeliveryNote)
        }).then(async function (response) {
            await response.json().then(data => {
                if (data.StatusCode === 400) {
                    throw new Error();
                }
                updated = true;
            })
        }).catch(() => {
            return false;
        })

        return updated;
    }

    acquireAccessToken = async () => {
        // const activeAccount = this.msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API        
        // const accounts = this.msalInstance.getAllAccounts();
        // if (!activeAccount && accounts.length === 0) {
        //     /*
        //     * User is not signed in. Throw error or wait for user to login.
        //     * Do not attempt to log a user in outside of the context of MsalProvider
        //     */
        // }    

        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }


        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}

function mapJsonObjectToDeliveryNote(jsonObject: any): DeliveryNote {
    return {
        id: jsonObject.Id,
        date: new Date(jsonObject.Date),
        signed: jsonObject.Signed,
        mailBoxName: jsonObject.MailBoxName,
        pdfReference: jsonObject.PdfReference,
        pdfBytes: jsonObject.PdfBytes,
        emailSubject: jsonObject.EmailSubject,
        code: jsonObject.Code,
        customerName: jsonObject.CustomerName,
        address: jsonObject.Address,
        city: jsonObject.City,
        postalCode: jsonObject.PostalCode,
        countryCode: jsonObject.CountryCode,
        contactName: jsonObject.ContactName,
        contactEmail: jsonObject.ContactEmail,
        deliveryAddress: jsonObject.DeliveryAddress
    };
}

import { apiLoginRequest } from "../Config";
import { IPublicClientApplication } from "@azure/msal-browser"

export default class MailBoxService {
    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async triggerFillDbWithMails(): Promise<boolean> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = `${baseUrl}/api/TriggerFillDbWithMails`;
        let updated = false;
        const accessToken = await this.acquireAccessToken();
        await fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        }).then(async function (response) {
            await response.json().then(data => {
                if (data.StatusCode === 500) {
                    throw new Error();
                }
                updated = true;
            })
        }).catch(() => {
            return false;
        })

        return updated;
    }

    acquireAccessToken = async () => {
        // const activeAccount = this.msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API        
        // const accounts = this.msalInstance.getAllAccounts();
        // if (!activeAccount && accounts.length === 0) {
        //     /*
        //     * User is not signed in. Throw error or wait for user to login.
        //     * Do not attempt to log a user in outside of the context of MsalProvider
        //     */
        // }    

        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }


        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}
import { Configuration, PopupRequest, RedirectRequest } from "@azure/msal-browser";

export const config = {
    appId: '8bc457a2-a3fd-431f-84b2-45bd6d513622',
    redirectUri: '' + process.env.REACT_APP_REDIRECTURI,
    authority: 'https://login.microsoftonline.com/cf65132a-cacc-43a0-ac70-a8109af73477',
    scopes: [
        'user.read',
        '' + process.env.REACT_APP_API_SCOPE
    ],
    graphScopes: [
        'user.read',
    ],
    apiScopes: [
        '' + process.env.REACT_APP_API_SCOPE
    ]
};

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "8bc457a2-a3fd-431f-84b2-45bd6d513622",
        authority: "https://login.microsoftonline.com/cf65132a-cacc-43a0-ac70-a8109af73477",
        redirectUri: '' + process.env.REACT_APP_REDIRECTURI,
        postLogoutRedirectUri: '' + process.env.REACT_APP_REDIRECTURI
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: ["User.Read",
        '' + process.env.REACT_APP_API_SCOPE]
};

export const graphLoginRequest: RedirectRequest = {
    scopes: ["User.Read"]
};

export const apiLoginRequest: RedirectRequest = {
    scopes: ['' + process.env.REACT_APP_API_SCOPE]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};
import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import {
    Button,
    Collapse,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import '@fortawesome/fontawesome-free/css/all.css';
import "./NavBar.css";
import { MsalContext, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { User } from 'microsoft-graph';
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "../Config";
import GraphService from "../Services/GraphService";

interface NavBarState {
    isOpen: boolean;
    settingsDropdownOpen: boolean;
}

function UserAvatar(props: any) {
    // If a user avatar is available, return an img tag with the pic
    if (props.user?.avatar) {
        return <img
            src={props.user?.avatar} alt="user"
            className="rounded-circle align-self-center mr-2"
            style={{ width: '32px' }}></img>;
    }

    // No avatar available, return a default icon
    return <i
        className="far fa-user-circle fa-lg rounded-circle align-self-center mr-2"
        style={{ width: '32px' }}></i>;
}

const AuthNavItem = () => {
    // If authenticated, return a dropdown with the user's info and a
    // sign out button
    const { inProgress, instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest);
    }

    if (isAuthenticated) {
        return (
            <AuthNavItemAuhtenicated />
        );
    }

    if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
        // Not authenticated, return a sign in link
        return (
            <NavItem>
                <Button style={{ color: "black" }}
                    onClick={() => handleLogin()}
                    className="btn-link nav-link border-0"
                    color="link">Aanmelden</Button>
            </NavItem>
        );
    }

    return null;
}

const AuthNavItemAuhtenicated = () => {
    // If authenticated, return a dropdown with the user's info and a
    // sign out button
    const { inProgress, instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const service = React.useMemo(() => new GraphService(instance), [instance]);

    const [user, setUser] = React.useState<User | null>(null);

    React.useEffect(() => {
        if (!user) {
            getUser();
        }
    }, []);

    const getUser = async () => {
        let user = await service.getUserDetails();
        setUser(user);
    };

    const handleLogout = () => {
        instance.logoutRedirect();
    }

    if (isAuthenticated) {
        return (
            <UncontrolledDropdown>
                <DropdownToggle style={{ color: "black" }} nav caret>
                    <UserAvatar user={user} />
                </DropdownToggle>
                <DropdownMenu right>
                    <h5 className="dropdown-item-text mb-0">{user?.displayName}</h5>
                    <p className="dropdown-item-text text-muted mb-0">{user?.mail}</p>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => handleLogout()}>Afmelden</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }

    return null;
}

export default class NavBar extends React.Component<any, NavBarState> {

    context!: React.ContextType<typeof MsalContext>;
    static contextType = MsalContext;

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.settingsDropdownToggle = this.settingsDropdownToggle.bind(this);
        this.state = {
            isOpen: false,
            settingsDropdownOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    settingsDropdownToggle() {
        this.setState({
            settingsDropdownOpen: !this.state.settingsDropdownOpen
        });
    }

    closeNavBar() {
        this.setState({ isOpen: false })
    }

    render() {
        const isAuthenticated = this.context.accounts.length > 0;
        let statusLink: any = null;
        if (isAuthenticated)
            statusLink = (
                <>
                    <NavItem>
                        <RouterNavLink style={{ color: "black" }} to="/status" className="nav-link" onClick={() => this.closeNavBar()} exact>Status</RouterNavLink>
                    </NavItem>
                    <NavItem>
                        <RouterNavLink style={{ color: "black" }} to="/delivery-notes" className="nav-link" onClick={() => this.closeNavBar()} exact>Leveringsbonnen</RouterNavLink>
                    </NavItem>
                </>
            );

        return (
            <Navbar dark expand="lg" fixed="top" style={{ padding: "5px 4% 5px 5%", backgroundColor: '#e2e2e5' }}>
                <NavbarBrand href="/" style={{ color: "black" }}>
                    {/* <img src={ProfcoatLogo} alt="Profcoat" style={{width: "80px", height: "35px"}}/> */}
                    <b>Leveringsbonnentool</b>
                </NavbarBrand>
                <Button variant="secondary" className="toggler" style={{ backgroundColor: "#e2e2e5", borderColor: "#e2e2e5", boxShadow: "none" }}>
                    <i className="fas fa-bars" onClick={this.toggle} style={{ fontSize: "32px" }}></i>
                </Button>
                {/* <NavbarToggler onClick={this.toggle} /> */}

                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="mr-auto" navbar>
                        {/* <NavItem>
                            <RouterNavLink to="/" className="nav-link" exact>Home</RouterNavLink>
                        </NavItem> */}
                        {statusLink}
                    </Nav>
                    <Nav className="justify-content-end" navbar>
                        <AuthNavItem />
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}
import { User } from 'microsoft-graph';
import { graphLoginRequest } from "../Config";
import { IPublicClientApplication } from "@azure/msal-browser";

var graph = require('@microsoft/microsoft-graph-client');

export default class GraphService {
    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async getUserDetails(): Promise<User> {
        const client = await this.getAuthenticatedClient();

        const user = await client
            .api('/me')
            .select('displayName,mail,userPrincipalName,id')
            .get();

        return user;
    }

    getAuthenticatedClient = async () => {
        const accessToken = await this.acquireAccessToken();
        const client = graph.Client.init({
            authProvider: (done: any) => {
                done(null, accessToken);
            }
        });

        return client;
    }

    acquireAccessToken = async () => {
        // const activeAccount = this.msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API        
        // const accounts = this.msalInstance.getAllAccounts();
        // if (!activeAccount && accounts.length === 0) {
        //     /*
        //     * User is not signed in. Throw error or wait for user to login.
        //     * Do not attempt to log a user in outside of the context of MsalProvider
        //     */
        // }    

        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }


        const authResult = await this.msalInstance.acquireTokenSilent({
            ...graphLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}

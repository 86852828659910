import React from 'react';
import { useIsAuthenticated, useMsal, MsalAuthenticationTemplate, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import GraphService from "../Services/GraphService";
import { User } from 'microsoft-graph';
import { Jumbotron } from 'reactstrap';


const HomeContent = () => {

    const { inProgress, instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const service = React.useMemo(() => new GraphService(instance), [instance]);

    const [user, setUser] = React.useState<User | null>(null);

    React.useEffect(() => {
        if (!user) {
            getUser();
        }
    }, []);

    const getUser = async () => {
        let user = await service.getUserDetails();
        setUser(user);
    };

    // If authenticated, greet the user
    if (isAuthenticated) {
        return (
            <h4>Welkom {user?.displayName}!</h4>
        );
    }

    return (
        <h4>Welkom, meld je aan om verder te gaan.</h4>
    );
}

export default function Home() {

    return (
        <div className="container">
            <Jumbotron>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                    <HomeContent />
                </MsalAuthenticationTemplate>

                <UnauthenticatedTemplate>
                    <h4>Welkom, meld je aan om verder te gaan.</h4>
                </UnauthenticatedTemplate>
            </Jumbotron>
        </div>

    );

}
